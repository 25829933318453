"use client";

import {
  compressForCookie,
  decompressFromCookieOrDefault,
} from "@mpay/web-tickets/src/utilities/gzip/gzip";
import { atom, useAtom, useAtomValue } from "jotai";
import { parseCookies, setCookie } from "nookies";
import * as React from "react";

import {
  BasketTickets,
  EmptyBasket,
  basketTicketsValidator,
} from "../../basket/basketTicketsValidator";
import {
  CUSTOMER_BASKET_COOKIE_NAME,
  convertCookieStringToBasketState,
} from "./cookies";

export function ClearBasket() {
  React.useEffect(() => {
    clearBasketStateCookie();
  }, []);
  return null;
}

/**
 * Watch out for confusion between this and
 * `apps/web-tickets/app/(checkout)/s/[clientVanityUrl]/order/_lib/getBasketRealTickets.server.ts`
 * This one lets you read the cookie synchronously in the browser
 */
export function getBasketStateFromCookie() {
  const cookies = parseCookies();
  const basketStateString = cookies[CUSTOMER_BASKET_COOKIE_NAME];
  return convertCookieStringToBasketState(
    decompressFromCookieOrDefault(basketStateString)
  );
}

export function setBasketStateCookie(basketState: BasketTickets) {
  basketTicketsValidator.parse(basketState);
  setCookie(
    null,
    CUSTOMER_BASKET_COOKIE_NAME,
    compressForCookie(JSON.stringify(basketState)),
    {
      path: "/",
      sameSite: "none",
      secure: true,
    }
  );
}

export function clearBasketStateCookie() {
  setBasketStateCookie(EmptyBasket);
}

type F = ReturnType<typeof getBasketStateFromCookie>;
export function updateBasketStateCookie(updater: (basket: F) => F) {
  const basket = getBasketStateFromCookie();
  const next = updater(basket);
  setBasketStateCookie(next);
}

export function addRevealCodeToBasketCookieBrowser(code: string) {
  const basket = getBasketStateFromCookie();
  if (!basket.basket.revealCodes.map((x) => x.code).includes(code)) {
    basket.basket.revealCodes.push({ code: code });
  }
  setBasketStateCookie(basket);
}

export function removeRevealCodeFromBasketCookieBrowser(code: string) {
  const basket = getBasketStateFromCookie();
  if (basket.basket.revealCodes.map((x) => x.code).includes(code)) {
    basket.basket.revealCodes = basket.basket.revealCodes.filter(
      (revealCode) => revealCode.code !== code
    );
  }
  setBasketStateCookie(basket);
}
