"use client";

import { parseCookies, setCookie } from "nookies";

import { SESSION_ID_COOKIE_NAME } from "../../Checkout/basketMachine/cookies";

const { v7 } = require("uuid");

export function getSessionId(): string {
  const cookies = parseCookies();
  let sessionId = cookies[SESSION_ID_COOKIE_NAME];

  if (!sessionId || sessionId === "") {
    sessionId = v7();
    setCookie(null, SESSION_ID_COOKIE_NAME, sessionId, {
      path: "/",
      sameSite: "none",
      secure: true,
    });
  }

  return sessionId;
}
